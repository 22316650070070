<template>
  <PageTitleTop>
    <template v-slot:title>绑定信息</template>
    <Form ref="formValidate" :label-width="130" label-position="left">
      <div
        v-for="item of dataList"
        :key="item.bindBankNo"
        style="border-bottom: 1px solid #eee; margin-bottom: 30px"
      >
        <Row class="form-row">
          <Col span="12">
            <FormItem label="开户行行号">
              {{ item.bindBankNo }}
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="开户行名称">
              {{ item.bindBankName }}
            </FormItem>
          </Col>
        </Row>
        <Row class="form-row">
          <Col span="12">
            <FormItem label="开户行账号">
              {{ item.bindAcctNo }}
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem
              label="状态"
              :style="{
                color:
                  item.status === 0 ? '#f90' : item.status ? '#19be6b' : '',
              }"
            >
              {{ statusList[item.status] }}
            </FormItem>
          </Col>
        </Row>
      </div>
      <div v-if="dataList?.length === 0" class="none">暂无数据</div>
      <!-- <NoData v-if="dataList.length === 0"/> -->
    </Form>
  </PageTitleTop>
</template>

<script>
export default {
  name: 'AccountBindInfo',
  data () {
    return {
      dataList: [],
      statusList: {
        0: '待打款认证',
        1: '已认证',
      },
    }
  },
  created () {
    this.$store
      .dispatch('accountMoney/GetBindBankCardList', {})
      .then((res) => {
        if (res.returnCode === '1') {
          this.dataList = res.result
        }
      })
      .catch(() => {})
  },
  methods: {},
  components: {},
}
</script>

<style lang="less" scoped>
.ivu-input-fix {
  width: 80%;
}
/deep/ .section-main {
  margin-bottom: 20px;
}
.none {
  height: 400px;
  text-align: center;
  line-height: 400px;
  color: #999;
  background: rgba(255, 255, 255, 0.9);
}
</style>
